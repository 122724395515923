.croppr-container * {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}
.croppr-container img {
    vertical-align: middle;
    max-width: 100%;
}
.croppr {
    position: relative;
    display: inline-block;
}
.croppr-handle,
.croppr-imageClipped,
.croppr-overlay,
.croppr-region {
    position: absolute;
    top: 0;
}
.croppr-overlay {
    background: rgba(0, 0, 0, 0.5);
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    cursor: crosshair;
}
.croppr-region {
    border: 1px dashed rgba(0, 0, 0, 0.5);
    z-index: 3;
    cursor: move;
}
.croppr-imageClipped {
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    pointer-events: none;
}
.croppr-handle {
    border: 1px solid #000;
    background-color: #fff;
    width: 10px;
    height: 10px;
    z-index: 4;
}
