.progress_bar_div {
    height: 20px;
    margin-bottom: 5px;
    overflow: hidden;
    background-color: #f5f5f5;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress_bar_inner {
    font-size: 12px;
    /* color: #fff; */
    text-align: center;
    -webkit-transition: width 0.6s ease;
    -o-transition: width 0.6s ease;
    transition: width 0.6s ease;
}
