/* Custom css */

.css-star2billing {
    background-color: aqua;
}

/* Customize the tail.select js plugin */
.tail-select-container .select-handle {
    width: auto;
    color: #7d838e;
    background-color: #e2e8f0;
}
.tail-select-container .select-handle:hover {
    --tw-text-opacity: 1;
    background-color: rgb(var(--color-primary) / var(--tw-text-opacity));
    color: white;
}

.tail-select {
    width: 100%;
    position: relative;
    line-height: 1.5rem;
    font-family: inherit;
    border-width: 1px;
    border-radius: 0px;
    margin-top: 0.1rem;
    padding-bottom: 0.1rem;
}
.tail-select .select-label {
    box-shadow: none;
    border-width: 1px;
    border-style: solid;
    border-color: #e2e8f0;
}
.dark .tail-select .select-label {
    border-color: transparent;
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-darkmode-800) / var(--tw-bg-opacity));
}

.tail-select,
::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #eb1935;
}

/*
Dropdown possition overwrite
This is used to position the dropdown high enough but still under the position of the datetime picker which is 3001 */
.dropdown-menu {
    z-index: 2000;
}

/* Toggle A */
input:checked ~ .dot {
    transform: translateX(100%);
    background-color: #216dd1;
}

/* top bar menu / darker area  */
.darker-menu {
    border-radius: 0.5rem;
    background-color: rgb(0 0 0 / 0.1);
}

.dark .nav.nav-tabs .nav-item .nav-link.active {
    --tw-border-opacity: 1;
    /* border-left-color: rgb(var(--color-darkmode-400) / var(--tw-border-opacity));
    border-right-color: rgb(var(--color-darkmode-400) / var(--tw-border-opacity));
    border-top-color: rgb(var(--color-darkmode-400) / var(--tw-border-opacity));
    border-bottom-color: rgb(var(--color-darkmode-600) / var(--tw-border-opacity)); */
    background-color: rgb(var(--color-darkmode-600) / var(--tw-border-opacity));
}
.dark .nav.nav-tabs .nav-item .nav-link:hover:not(.disabled):not(.active):hover {
    /* border-color: transparent; */
    --tw-border-opacity: 1;
    background-color: rgb(var(--color-darkmode-600) / var(--tw-border-opacity));
}

/* overwrite checkbox in dark mode */
.dark .form-check-input[type='checkbox'] {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-darkmode-200) / var(--tw-bg-opacity));
    border-color: rgb(var(--color-darkmode-600) / var(--tw-bg-opacity));
}
