.beautable {
    @apply min-w-full divide-y divide-slate-200 dark:divide-darkmode-800;
    thead {
        @apply bg-slate-50 dark:bg-darkmode-600;
        tr {
            @apply text-slate-500 dark:text-slate-400 text-xs font-medium uppercase tracking-wider;
            th {
                @apply px-4 py-4 text-left;
                &:last-child {
                    @apply px-4 py-4 text-center items-center;
                }
            }
            th.center {
                @apply px-4 py-4 text-center;
                &:last-child {
                    @apply px-4 py-4 text-center items-center;
                }
            }
        }
    }
    tfoot {
        @apply bg-slate-50 text-slate-500 dark:bg-darkmode-600 italic text-left;
        tr {
            th {
                @apply px-4 py-4 text-xs font-normal;
            }
        }
    }
    tbody {
        @apply bg-white divide-y divide-slate-200 text-slate-600 dark:bg-darkmode-300 dark:text-slate-400 dark:divide-darkmode-600;
        tr {
            @apply hover:bg-slate-50/50 dark:hover:bg-darkmode-400/50;
            td {
                @apply px-4 py-4 whitespace-nowrap;
                &:last-child {
                    @apply px-4 py-4 text-center items-center;
                }
            }
        }
    }
}
