html {
    @apply bg-primary;
    &.dark {
        @apply bg-darkmode-800;
        body {
            @apply text-slate-300;
            *,
            ::before,
            ::after {
                @apply border-white/5;
            }
        }
    }
    body {
        @apply antialiased overflow-x-hidden py-3 px-8 font-roboto text-sm text-slate-800;

        /* areski: Padding changed to make the left menu smaller */
        @apply px-1;

        @include media-breakpoint-down(sm) {
            @apply px-1;
        }
    }
}
