@layer components {
    .pill-border {
        @apply inline-flex items-center px-2 py-1 rounded-full border text-slate-500 dark:text-slate-600 dark:border-slate-500 mr-1;
    }
    .pill-border-slate {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 rounded-full border text-slate-400 dark:text-slate-500 dark:border-slate-500 mr-1 pr-3;
    }

    .pill-slate-btn-hover {
        @apply inline-flex items-center text-xs px-2 py-1 rounded-full border border-slate-400 text-slate-400 dark:border-darkmode-100/40 dark:text-slate-500 dark:hover:text-slate-700 dark:hover:bg-slate-500 mr-1 pr-2 bg-slate-50 hover:bg-slate-100 hover:text-slate-600 hover:border-slate-600;
        /* font-semibold py-1 px-2 uppercase rounded-full text-indigo-500 dark:text-indigo-50 mr-1 pr-3; */
    }

    .pill-transparent {
        @apply inline-flex items-center px-2 py-1 text-slate-500 dark:text-slate-500 mr-1;
    }
    .pill-transparent-notext {
        @apply inline-flex items-center px-2 py-1 mr-1;
    }

    .pill-white {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-slate-400 dark:text-slate-500 bg-slate-50 dark:bg-slate-200 mr-1 pr-3;
    }
    .pill-slate {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-slate-400 dark:text-slate-50 bg-slate-100 dark:bg-slate-400 mr-1 pr-3;
    }

    .pill-fuchsia {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-fuchsia-500 dark:text-fuchsia-50 bg-fuchsia-100 dark:bg-fuchsia-400 mr-1 pr-3;
    }

    .pill-fuchsia-nobg {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-fuchsia-400 dark:text-fuchsia-300 border border-fuchsia-200 dark:border-fuchsia-400 mr-1 pr-3;
    }

    .pill-emerald {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-emerald-500 dark:text-emerald-50 bg-emerald-100 dark:bg-emerald-400 mr-1 pr-3;
    }

    .pill-emerald-nobg {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-emerald-400 dark:text-emerald-300 border border-emerald-200 dark:border-emerald-400 mr-1 pr-3;
    }

    .pill-darker-emerald {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-emerald-600 dark:text-emerald-700 bg-emerald-200 dark:bg-emerald-400 mr-1 pr-3;
    }

    .pill-darker-emerald-nobg {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-emerald-500  dark:text-emerald-500 border border-emerald-500 dark:border-emerald-500 mr-1 pr-3;
    }

    .pill-green {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-green-500 dark:text-green-50 bg-green-100 dark:bg-green-400 mr-1 pr-3;
    }

    .pill-green-nobg {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-green-400 dark:text-green-300 border border-green-200 dark:border-green-400 mr-1 pr-3;
    }
    .pill-white-nobg {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-slate-400 bg-white dark:text-slate-300 border border-slate-200 dark:border-slate-400 mr-1 pr-3;
    }
    .pill-slate-nobg {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-slate-400 dark:text-slate-300 border border-slate-200 dark:border-slate-400 mr-1 pr-3;
    }
    .pill-slate-darker-nobg {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-slate-500 dark:text-slate-400 border border-slate-400 dark:border-slate-600 mr-1 pr-3;
    }
    .pill-slate-nobg-noborder {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-slate-400 dark:text-slate-300  mr-1 pr-3;
    }

    .pill-indigo {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-indigo-500 dark:text-indigo-50 bg-indigo-100 dark:bg-indigo-400 mr-1 pr-3;
    }
    .pill-indigo-nobg {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-indigo-500 dark:text-indigo-50 mr-1 pr-3;
    }

    .pill-violet {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-violet-500 dark:text-violet-50 bg-violet-100 dark:bg-violet-400 m-1 pr-3;
    }
    .pill-violet-nobg {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase border rounded-full text-violet-500 border-violet-500 dark:text-violet-50 m-1 pr-3;
    }

    .pill-fuchsia {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-fuchsia-500 dark:text-fuchsia-50 bg-fuchsia-100 dark:bg-fuchsia-400 m-1 pr-3;
    }

    .pill-sky {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-sky-500 dark:text-sky-50 bg-sky-100 dark:bg-sky-400 mr-1 pr-3;
    }

    .pill-sky-nobg {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-sky-400 dark:text-sky-300 border border-sky-200 dark:border-sky-400 mr-1 pr-3;
    }

    .pill-teal {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-teal-500 dark:text-teal-50 bg-teal-100 dark:bg-teal-400 mr-1 pr-3;
    }

    .pill-teal-nobg {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-teal-400 dark:text-teal-300 border border-teal-200 dark:border-teal-400 mr-1 pr-3;
    }

    .pill-blue {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-blue-500 dark:text-blue-50 bg-blue-100 dark:bg-blue-400 mr-1 pr-3;
    }

    .pill-blue-nobg {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-blue-400 dark:text-blue-300 border border-blue-200 dark:border-blue-400 mr-1 pr-3;
    }
    .pill-blue-nobg-noborder {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-blue-400 dark:text-blue-300  mr-1 pr-3;
    }

    .pill-indigo-nobg {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-indigo-400 dark:text-indigo-300 border border-indigo-200 dark:border-indigo-400 mr-1 pr-3;
    }
    .pill-indigo-nobg-noborder {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-indigo-400 dark:text-indigo-300  mr-1 pr-3;
    }

    .pill-darker-blue {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-100 dark:text-blue-100 dark:bg-blue-600 mr-1 pr-3;
    }

    .pill-darker-blue-hover {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 rounded-md text-blue-600 bg-blue-100 border border-blue-600 dark:text-blue-100 dark:bg-blue-600 hover:bg-blue-600 hover:text-blue-50;
    }

    .pill-white-blue-hover {
        @apply inline-flex items-center font-semibold py-1 px-2 rounded-md text-slate-400 bg-white border border-slate-300 dark:text-slate-500 dark:bg-slate-200 hover:bg-slate-400 hover:text-blue-50;
    }

    .pill-slate-blue-hover-nobg-noborder {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-blue-500 dark:text-blue-300 hover:text-blue-700 hover:dark:text-blue-100 hover:bg-blue-50 mr-1 pr-3;
    }

    .pill-slate-hover-nobg {
        @apply inline-flex items-center text-xs font-semibold py-2 px-2 uppercase rounded-full text-slate-500 dark:text-slate-300 hover:text-slate-700 hover:dark:text-slate-100 hover:bg-slate-50 border border-slate-300 mr-1 pr-3;
    }

    .pill-red {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-red-500 dark:text-red-50 bg-red-100 dark:bg-red-400 mr-1 pr-3;
    }

    .pill-red-nobg {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-red-400 dark:text-red-50 border border-red-400 dark:border-red-400 mr-1 pr-3;
    }

    .pill-red-nobg-noborder {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-red-400 dark:text-red-50 mr-1 pr-3;
    }

    .pill-red-nobg-hover {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-red-500 dark:text-red-50 border border-red-400 dark:border-red-400 mr-1 pr-3 hover:bg-red-400 hover:text-red-50;
    }

    .pill-darker-red {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-red-500 bg-red-100 dark:text-red-100 dark:bg-red-500 mr-1 pr-3;
    }

    .pill-darker-red-nobg {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-red-500 dark:text-red-100 border border-red-400 dark:border-red-400 mr-1 pr-3;
    }

    .pill-pink {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-pink-500 dark:text-pink-300 bg-pink-100 dark:bg-red-400 mr-1 pr-3;
    }

    .pill-pink-nobg {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-pink-400 dark:text-pink-300 border border-pink-400 dark:border-pink-400 mr-1 pr-3;
    }
    .pill-pink-nobg-noborder {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-pink-400 dark:text-pink-500  mr-1 pr-3;
    }
    .pill-darker-pink {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-pink-50 bg-pink-300 dark:text-pink-100 dark:bg-pink-600 mr-1 pr-3;
    }
    .pill-darker-pink-nobg {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-pink-600 dark:text-pink-800 border border-pink-600 dark:border-pink-600 mr-1 pr-3;
    }

    .pill-amber {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-amber-500 dark:text-amber-50 bg-amber-100 dark:bg-amber-400 mr-1 pr-3;
    }

    .pill-amber-nobg {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-amber-400 dark:text-amber-300 border border-amber-200 dark:border-amber-400 mr-1 pr-3;
    }
    .pill-amber-nobg-noborder {
        @apply inline-flex items-center text-xs font-semibold py-1 px-2 uppercase rounded-full text-amber-400 dark:text-amber-300  mr-1 pr-3;
    }

    /* Small pills */
    .tinypill-indigo {
        @apply inline-flex items-center text-xs rounded-full text-indigo-400 dark:text-indigo-50 bg-indigo-100 dark:bg-indigo-400 mr-1 pl-1 pr-2;
    }
    .tinypill-indigo-nobg {
        @apply inline-flex items-center text-xs rounded-full text-indigo-400 dark:text-indigo-300 border border-indigo-300 dark:border-indigo-200 mr-1 pl-1 pr-2;
    }
    .tinypill-pink {
        @apply inline-flex items-center text-xs rounded-full text-pink-400 dark:text-pink-50 bg-pink-100 dark:bg-pink-400 mr-1 pl-1 pr-2;
    }
    .tinypill-pink-nobg {
        @apply inline-flex items-center text-xs rounded-full text-pink-400 dark:text-pink-300 border border-pink-300 dark:border-pink-200 mr-1 pl-1 pr-2;
    }
    .tinypill-teal {
        @apply inline-flex items-center text-xs rounded-full text-teal-400 dark:text-teal-50 bg-teal-100 dark:bg-teal-400 mr-1 pl-1 pr-2;
    }
    .tinypill-teal-nobg {
        @apply inline-flex items-center text-xs rounded-full text-teal-400 dark:text-teal-300 border border-teal-300 dark:border-teal-200 mr-1 pl-1 pr-2;
    }
    .tinypill-sky {
        @apply inline-flex items-center text-xs rounded-full text-sky-400 dark:text-sky-50 bg-sky-100 dark:bg-sky-400 mr-1 pl-1 pr-2;
    }
    .tinypill-sky-nobg {
        @apply inline-flex items-center text-xs rounded-full text-sky-400 dark:text-sky-300 border border-sky-300 dark:border-sky-200 mr-1 pl-1 pr-2;
    }
    .tinypill-green {
        @apply inline-flex items-center text-xs rounded-full text-green-400 dark:text-green-50 bg-green-100 dark:bg-green-400 mr-1 pl-1 pr-2;
    }
    .tinypill-green-nobg {
        @apply inline-flex items-center text-xs rounded-full text-green-400 dark:text-green-300 border border-green-300 dark:border-green-200 mr-1 pl-1 pr-2;
    }
    .tinypill-fuchsia {
        @apply inline-flex items-center text-xs rounded-full text-fuchsia-400 dark:text-fuchsia-50 bg-fuchsia-100 dark:bg-fuchsia-400 mr-1 pl-1 pr-2;
    }
    .tinypill-fuchsia-nobg {
        @apply inline-flex items-center text-xs rounded-full text-fuchsia-400 dark:text-fuchsia-300 border border-fuchsia-300 dark:border-fuchsia-200 mr-1 pl-1 pr-2;
    }
    .tinypill-violet {
        @apply inline-flex items-center text-xs rounded-full text-violet-400 dark:text-violet-50 bg-violet-100 dark:bg-violet-400 mr-1 pl-1 pr-2;
    }
    .tinypill-violet-nobg {
        @apply inline-flex items-center text-xs rounded-full text-violet-400 dark:text-violet-300 border border-violet-300 dark:border-violet-200 mr-1 pl-1 pr-2;
    }
    .tinypill-slate {
        @apply inline-flex items-center text-xs rounded-full text-slate-400 dark:text-slate-50 bg-slate-100 dark:bg-slate-400 mr-1 pl-1 pr-2;
    }
    .tinypill-slate-nobg {
        @apply inline-flex items-center text-xs rounded-full text-slate-400 dark:text-slate-300 border border-slate-300 dark:border-slate-200 mr-1 pl-1 pr-2;
    }
    .tinypill-border-slate {
        @apply inline-flex items-center text-xs rounded-full border text-slate-400 dark:text-slate-600 dark:bg-slate-500 dark:border-slate-500 mr-1 pl-1 pr-2;
    }
    .tinypill-noborder-slate {
        @apply inline-flex items-center text-xs rounded-full text-slate-400 dark:text-slate-400 mr-1 pl-1 pr-2;
    }
    .tinypill-noborder-pink {
        @apply inline-flex items-center text-xs rounded-full text-pink-400 dark:text-pink-400 dark:bg-pink-500 dark:border-pink-500 mr-1 pl-1 pr-2;
    }
    .tinypill-blue-hover {
        @apply inline-flex items-center text-xs rounded-full text-blue-400 dark:text-blue-50 bg-blue-200 dark:bg-blue-500
    m-1 px-2 hover:bg-blue-400 hover:text-blue-50;
    }
    .tinypill-green-hover {
        @apply inline-flex items-center text-xs rounded-full text-green-400 dark:text-green-50 bg-green-200 dark:bg-green-500
    m-1 px-2 hover:bg-green-400 hover:text-green-50;
    }
    .tinypill-pink-hover {
        @apply inline-flex items-center text-xs rounded-full text-pink-400 dark:text-pink-50 bg-pink-100 dark:bg-pink-400 mr-1 pl-1 pr-2 hover:bg-pink-400 hover:text-pink-50;
    }

    /* Stats pills */
    .pill-amber-border-stats {
        @apply text-xs font-semibold py-1 px-2 uppercase rounded-md text-amber-400 dark:text-amber-50 border-amber-200 dark:border-amber-50 bg-amber-50 dark:bg-amber-400 mr-1 pr-3;
    }
    .pill-blue-border-stats {
        @apply text-xs font-semibold py-1 px-2 uppercase rounded-md text-blue-400 dark:text-blue-50 border-blue-200 dark:border-blue-50 bg-blue-50 dark:bg-blue-400 mr-1 pr-3;
    }
    .pill-emerald-border-stats {
        @apply text-xs font-semibold py-1 px-2 uppercase rounded-md text-emerald-400 dark:text-emerald-50 border-emerald-200 dark:border-emerald-50 bg-emerald-50 dark:bg-emerald-400 mr-1 pr-3;
    }
}
