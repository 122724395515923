@layer components {
    .bt-empty {
        @apply btn bg-slate-50 hover:bg-slate-200 text-slate-500 shadow-sm;
    }

    .bt-primary {
        @apply btn btn-primary shadow-sm;
    }
    .bt-secondary {
        @apply btn btn-secondary shadow-sm;
    }
    .bt-success {
        @apply btn btn-success shadow-sm;
    }
    .bt-info {
        @apply btn btn-info shadow-sm;
    }
    .bt-warning {
        @apply btn btn-warning shadow-sm;
    }
    .bt-pending {
        @apply btn btn-pending shadow-sm;
    }
    .bt-danger {
        @apply btn btn-danger shadow-sm;
    }
    .bt-light {
        @apply btn btn-light shadow-sm;
    }
    .bt-dark {
        @apply btn btn-dark shadow-sm;
    }

    .bt-outline-primary {
        @apply btn btn-outline-primary shadow-sm;
    }
    .bt-outline-secondary {
        @apply btn btn-outline-secondary shadow-sm;
    }
    .bt-outline-success {
        @apply btn btn-outline-success shadow-sm;
    }
    .bt-outline-info {
        @apply btn btn-outline-info shadow-sm;
    }
    .bt-outline-warning {
        @apply btn btn-outline-warning shadow-sm;
    }
    .bt-outline-pending {
        @apply btn btn-outline-pending shadow-sm;
    }
    .bt-outline-danger {
        @apply btn btn-outline-danger shadow-sm;
    }
    .bt-outline-light {
        @apply btn btn-outline-light shadow-sm;
    }
    .bt-outline-dark {
        @apply btn btn-outline-dark shadow-sm;
    }

    /* other buttons */

    .bt-outline-primary-bg-white {
        @apply btn btn-outline-primary bg-white hover:bg-slate-100 shadow;
    }

    .bt-twitter {
        @apply btn btn-twitter shadow-sm;
    }
}
