.side-nav {
    width: 210px;
    @apply pr-1 pb-16 overflow-x-hidden;
    @include media-breakpoint-down(xl) {
        width: 65px;
        @apply pr-1;
    }
    @include media-breakpoint-down(md) {
        @apply hidden;
    }
    &.side-nav--simple {
        width: 85px;
        .side-menu {
            .side-menu__title {
                @apply hidden;
                .side-menu__sub-icon {
                    @apply hidden;
                }
            }
            &:not(.side-menu--active) .side-menu__icon:before {
                @apply hidden;
            }
        }
    }
    .side-nav__devider {
        @apply w-full h-px bg-white/[0.08] z-10 relative dark:bg-white/[0.07];
    }
    .side-menu {
        height: 50px;
        @apply flex items-center pl-5 text-white mb-1 relative rounded-full dark:text-slate-300;
        .side-menu__title {
            @apply w-full ml-3 flex items-center;
            .side-menu__sub-icon {
                @apply transition ease-in duration-100 ml-auto mr-5;
                svg {
                    @apply w-4 h-4;
                }
                @include media-breakpoint-down(xl) {
                    @apply hidden;
                }
            }
            @include media-breakpoint-down(xl) {
                @apply hidden;
            }
        }
    }
    > ul {
        @for $i from 1 through 50 {
            > li:nth-child($i) {
                &.side-nav__devider {
                    @apply opacity-0;
                    animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
                    animation-fill-mode: forwards;
                    animation-delay: calc($i * 0.1s);
                }
                & > a {
                    &:not(.side-menu--active) {
                        @apply opacity-0;
                        transform: translateX(50px);
                        animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
                        animation-fill-mode: forwards;
                        animation-delay: calc($i * 0.1s);
                    }
                    &.side-menu--active {
                        animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
                        animation-fill-mode: forwards;
                    }
                }
            }
        }
        ul {
            @for $i from 1 through 50 {
                li:nth-child($i) {
                    & > a {
                        @apply opacity-0;
                        transform: translateX(50px);
                        animation: 0.2s intro-submenu-animation ease-in-out 0.33333s;
                        animation-fill-mode: forwards;
                        animation-delay: calc($i * 0.1s);
                    }
                }
            }
            @apply hidden bg-black/10 rounded-lg dark:bg-darkmode-900/30;
            &.side-menu__sub-open {
                @apply block;
            }
            .side-menu__icon:before {
                @apply hidden;
            }
            li a {
                &:not(.side-menu--active) {
                    @apply text-white/70 dark:text-slate-400;
                }
                &.side-menu--active .side-menu__title {
                    @apply font-medium;
                }
            }
            ul {
                @apply hidden bg-black/10 rounded-lg dark:bg-darkmode-900/30;
            }
        }
        & > li {
            & > .side-menu {
                &.side-menu--active {
                    @apply bg-slate-100 dark:bg-darkmode-700;
                    &:before {
                        content: '';
                        width: 30px;
                        height: 30px;
                        margin-top: -30px;
                        transform: rotate(90deg) scale(1.04);
                        background-size: 100%;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='259.51' height='259.52' viewBox='0 0 259.51 259.52'%3E%3Cpath id='Path_143' data-name='Path 143' d='M8659.507,423.965c-.167-2.608.05-5.319-.19-8.211-.084-1.012-.031-2.15-.118-3.12-.113-1.25-.1-2.682-.236-4.061-.172-1.722-.179-3.757-.365-5.394-.328-2.889-.478-5.857-.854-8.61-.509-3.714-.825-7.252-1.38-10.543-.934-5.535-2.009-11.312-3.189-16.692-.855-3.9-1.772-7.416-2.752-11.2-1.1-4.256-2.394-8.149-3.687-12.381-1.1-3.615-2.366-6.893-3.623-10.493-1.3-3.739-2.917-7.26-4.284-10.7-1.708-4.295-3.674-8.078-5.485-12.023-1.145-2.493-2.5-4.932-3.727-7.387-1.318-2.646-2.9-5.214-4.152-7.518-1.716-3.16-3.517-5.946-5.274-8.873-1.692-2.818-3.589-5.645-5.355-8.334-2.326-3.542-4.637-6.581-7.039-9.848-2.064-2.809-4.017-5.255-6.088-7.828-2.394-2.974-4.937-5.936-7.292-8.589-3.027-3.411-6.049-6.744-9.055-9.763-2.4-2.412-4.776-4.822-7.108-6.975-3-2.767-5.836-5.471-8.692-7.854-3.332-2.779-6.657-5.663-9.815-8.028-2.958-2.216-5.784-4.613-8.7-6.6-3.161-2.159-6.251-4.414-9.219-6.254-3.814-2.365-7.533-4.882-11.168-6.89-4.213-2.327-8.513-4.909-12.478-6.834-4.61-2.239-9.234-4.619-13.51-6.416-4.1-1.725-8.11-3.505-11.874-4.888-4.5-1.652-8.506-3.191-12.584-4.47-6.045-1.9-12.071-3.678-17.431-5-9.228-2.284-17.608-3.757-24.951-4.9-7.123-1.112-13.437-1.64-18.271-2.035l-2.405-.2c-1.638-.136-3.508-.237-4.633-.3a115.051,115.051,0,0,0-12.526-.227h259.51Z' transform='translate(-8399.997 -164.445)' fill='%23f1f5f8'/%3E%3C/svg%3E%0A");
                        @apply absolute top-0 right-0 -mr-5;
                    }
                    &:after {
                        content: '';
                        width: 30px;
                        height: 30px;
                        margin-top: 50px;
                        transform: scale(1.04);
                        background-size: 100%;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='259.51' height='259.52' viewBox='0 0 259.51 259.52'%3E%3Cpath id='Path_143' data-name='Path 143' d='M8659.507,423.965c-.167-2.608.05-5.319-.19-8.211-.084-1.012-.031-2.15-.118-3.12-.113-1.25-.1-2.682-.236-4.061-.172-1.722-.179-3.757-.365-5.394-.328-2.889-.478-5.857-.854-8.61-.509-3.714-.825-7.252-1.38-10.543-.934-5.535-2.009-11.312-3.189-16.692-.855-3.9-1.772-7.416-2.752-11.2-1.1-4.256-2.394-8.149-3.687-12.381-1.1-3.615-2.366-6.893-3.623-10.493-1.3-3.739-2.917-7.26-4.284-10.7-1.708-4.295-3.674-8.078-5.485-12.023-1.145-2.493-2.5-4.932-3.727-7.387-1.318-2.646-2.9-5.214-4.152-7.518-1.716-3.16-3.517-5.946-5.274-8.873-1.692-2.818-3.589-5.645-5.355-8.334-2.326-3.542-4.637-6.581-7.039-9.848-2.064-2.809-4.017-5.255-6.088-7.828-2.394-2.974-4.937-5.936-7.292-8.589-3.027-3.411-6.049-6.744-9.055-9.763-2.4-2.412-4.776-4.822-7.108-6.975-3-2.767-5.836-5.471-8.692-7.854-3.332-2.779-6.657-5.663-9.815-8.028-2.958-2.216-5.784-4.613-8.7-6.6-3.161-2.159-6.251-4.414-9.219-6.254-3.814-2.365-7.533-4.882-11.168-6.89-4.213-2.327-8.513-4.909-12.478-6.834-4.61-2.239-9.234-4.619-13.51-6.416-4.1-1.725-8.11-3.505-11.874-4.888-4.5-1.652-8.506-3.191-12.584-4.47-6.045-1.9-12.071-3.678-17.431-5-9.228-2.284-17.608-3.757-24.951-4.9-7.123-1.112-13.437-1.64-18.271-2.035l-2.405-.2c-1.638-.136-3.508-.237-4.633-.3a115.051,115.051,0,0,0-12.526-.227h259.51Z' transform='translate(-8399.997 -164.445)' fill='%23f1f5f8'/%3E%3C/svg%3E%0A");
                        @apply absolute top-0 right-0 -mr-5;
                    }
                    .side-menu__icon {
                        @apply text-primary dark:text-slate-300;
                        &:before {
                            content: '';
                            z-index: -1;
                            @apply absolute top-0 right-0 -mr-5 w-12 h-full bg-slate-100 dark:bg-darkmode-700;
                        }
                    }
                    .side-menu__title {
                        @apply text-slate-800 font-medium dark:text-slate-300;
                    }
                }
                .side-menu__icon {
                    @apply dark:text-slate-400;
                }
                .side-menu__title {
                    @apply dark:text-slate-400;
                }
                &:not(.side-menu--active) .side-menu__icon:before {
                    content: '';
                    z-index: -1;
                    width: 230px;
                    @apply absolute top-0 left-0 h-full rounded-l-full transition ease-in duration-100;
                    @include media-breakpoint-down(xl) {
                        @apply hidden;
                    }
                }
                &:hover:not(.side-menu--active):not(.side-menu--open) .side-menu__icon:before {
                    @apply bg-white/5 dark:bg-darkmode-500/70;
                }
            }
        }
    }
}

@keyframes intro-devider-animation {
    100% {
        @apply opacity-100;
    }
}

@keyframes intro-menu-animation {
    100% {
        @apply opacity-100;
        transform: translateX(0px);
    }
}

@keyframes intro-submenu-animation {
    100% {
        @apply opacity-100;
        transform: translateX(0px);
    }
}

@keyframes intro-active-menu-animation {
    100% {
        @apply z-10;
    }
}

.dark {
    .side-nav {
        > ul {
            & > li {
                & > .side-menu {
                    &.side-menu--active {
                        &:before {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='259.51' height='259.52' viewBox='0 0 259.51 259.52'%3E%3Cpath id='Path_143' data-name='Path 143' d='M8659.507,423.965c-.167-2.608.05-5.319-.19-8.211-.084-1.012-.031-2.15-.118-3.12-.113-1.25-.1-2.682-.236-4.061-.172-1.722-.179-3.757-.365-5.394-.328-2.889-.478-5.857-.854-8.61-.509-3.714-.825-7.252-1.38-10.543-.934-5.535-2.009-11.312-3.189-16.692-.855-3.9-1.772-7.416-2.752-11.2-1.1-4.256-2.394-8.149-3.687-12.381-1.1-3.615-2.366-6.893-3.623-10.493-1.3-3.739-2.917-7.26-4.284-10.7-1.708-4.295-3.674-8.078-5.485-12.023-1.145-2.493-2.5-4.932-3.727-7.387-1.318-2.646-2.9-5.214-4.152-7.518-1.716-3.16-3.517-5.946-5.274-8.873-1.692-2.818-3.589-5.645-5.355-8.334-2.326-3.542-4.637-6.581-7.039-9.848-2.064-2.809-4.017-5.255-6.088-7.828-2.394-2.974-4.937-5.936-7.292-8.589-3.027-3.411-6.049-6.744-9.055-9.763-2.4-2.412-4.776-4.822-7.108-6.975-3-2.767-5.836-5.471-8.692-7.854-3.332-2.779-6.657-5.663-9.815-8.028-2.958-2.216-5.784-4.613-8.7-6.6-3.161-2.159-6.251-4.414-9.219-6.254-3.814-2.365-7.533-4.882-11.168-6.89-4.213-2.327-8.513-4.909-12.478-6.834-4.61-2.239-9.234-4.619-13.51-6.416-4.1-1.725-8.11-3.505-11.874-4.888-4.5-1.652-8.506-3.191-12.584-4.47-6.045-1.9-12.071-3.678-17.431-5-9.228-2.284-17.608-3.757-24.951-4.9-7.123-1.112-13.437-1.64-18.271-2.035l-2.405-.2c-1.638-.136-3.508-.237-4.633-.3a115.051,115.051,0,0,0-12.526-.227h259.51Z' transform='translate(-8399.997 -164.445)' fill='%23232e45'/%3E%3C/svg%3E%0A");
                        }
                        &:after {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='259.51' height='259.52' viewBox='0 0 259.51 259.52'%3E%3Cpath id='Path_143' data-name='Path 143' d='M8659.507,423.965c-.167-2.608.05-5.319-.19-8.211-.084-1.012-.031-2.15-.118-3.12-.113-1.25-.1-2.682-.236-4.061-.172-1.722-.179-3.757-.365-5.394-.328-2.889-.478-5.857-.854-8.61-.509-3.714-.825-7.252-1.38-10.543-.934-5.535-2.009-11.312-3.189-16.692-.855-3.9-1.772-7.416-2.752-11.2-1.1-4.256-2.394-8.149-3.687-12.381-1.1-3.615-2.366-6.893-3.623-10.493-1.3-3.739-2.917-7.26-4.284-10.7-1.708-4.295-3.674-8.078-5.485-12.023-1.145-2.493-2.5-4.932-3.727-7.387-1.318-2.646-2.9-5.214-4.152-7.518-1.716-3.16-3.517-5.946-5.274-8.873-1.692-2.818-3.589-5.645-5.355-8.334-2.326-3.542-4.637-6.581-7.039-9.848-2.064-2.809-4.017-5.255-6.088-7.828-2.394-2.974-4.937-5.936-7.292-8.589-3.027-3.411-6.049-6.744-9.055-9.763-2.4-2.412-4.776-4.822-7.108-6.975-3-2.767-5.836-5.471-8.692-7.854-3.332-2.779-6.657-5.663-9.815-8.028-2.958-2.216-5.784-4.613-8.7-6.6-3.161-2.159-6.251-4.414-9.219-6.254-3.814-2.365-7.533-4.882-11.168-6.89-4.213-2.327-8.513-4.909-12.478-6.834-4.61-2.239-9.234-4.619-13.51-6.416-4.1-1.725-8.11-3.505-11.874-4.888-4.5-1.652-8.506-3.191-12.584-4.47-6.045-1.9-12.071-3.678-17.431-5-9.228-2.284-17.608-3.757-24.951-4.9-7.123-1.112-13.437-1.64-18.271-2.035l-2.405-.2c-1.638-.136-3.508-.237-4.633-.3a115.051,115.051,0,0,0-12.526-.227h259.51Z' transform='translate(-8399.997 -164.445)' fill='%23232e45'/%3E%3C/svg%3E%0A");
                        }
                    }
                }
            }
        }
    }
}
