.tom-select {
    .ts-control {
        min-height: 23px;
        padding: 7.5px 32px 7.5px 12px;
        /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='rgb(74 85 104)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='lucide lucide-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'/%3E%3C/svg%3E");
        background-size: 18px;
        background-position: center right 0.6rem; */
        @apply text-sm shadow-sm border-slate-200 bg-no-repeat rounded;
        .item {
            line-height: 22px;
        }
        /* &.dropdown-active {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.828' height='8.414' viewBox='0 0 14.828 8.414'%3E%3Cpath id='chevron' d='M6,9l6,6,6-6' transform='translate(19.414 16) rotate(180)' fill='none' stroke='%234a5568' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E%0A") !important;
            background-size: 11px !important;
            background-position: center right 0.8rem !important;
            background-repeat: no-repeat !important;
        } */
        input {
            height: 23px;
            @apply text-sm;
        }
    }
    &.multi .ts-control {
        @apply px-1.5 py-1.5;
        & > div {
            @apply rounded my-0.5 mr-1 px-2 py-0 bg-slate-200;
            &.active {
                @apply bg-slate-300;
                .remove {
                    @apply border-slate-400;
                }
            }
        }
    }
    &.plugin-remove_button .item .remove {
        @apply border-slate-300 ml-2 px-2;
    }
    &.plugin-dropdown_input .ts-control.focus {
        @apply border-slate-200;
    }
    .ts-dropdown {
        margin-top: -1px;
        border-top: 1px solid rgba(226, 232, 240, var(--tw-border-opacity));
        @apply z-50 text-sm shadow-sm border-slate-200 rounded;
        .optgroup-header {
            padding: 9px 10px;
            @apply font-medium;
        }
        .option {
            padding: 9px 13px;
            .highlight {
                @apply bg-danger text-white;
            }
            &.active {
                @apply bg-primary text-white;
            }
            &:hover:not(.active) {
                @apply bg-slate-200 text-slate-800;
            }
        }
        &.plugin-dropdown_input .dropdown-input-wrap {
            @apply p-2;
            input {
                @apply outline-none border border-slate-200 rounded py-2 px-3;
            }
        }
        .dropdown-header {
            @apply px-3 bg-slate-100 border-slate-200;
            .dropdown-header-close {
                @apply cursor-pointer opacity-100 text-slate-400 mr-0.5;
            }
        }
    }
}

.dark {
    .tom-select {
        .ts-control {
            /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='rgb(255 255 255)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='lucide lucide-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'/%3E%3C/svg%3E"); */
            @apply bg-darkmode-800 border-transparent text-slate-300;
            &.dropdown-active {
                @apply bg-darkmode-800 border-transparent text-slate-300;
                /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.828' height='8.414' viewBox='0 0 14.828 8.414'%3E%3Cpath id='chevron' d='M6,9l6,6,6-6' transform='translate(19.414 16) rotate(180)' fill='none' stroke='rgb(255 255 255)' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E%0A") !important; */
                &:before {
                    @apply bg-darkmode-800;
                }
            }
        }
        &.multi .ts-control {
            & > div {
                @apply bg-darkmode-600 border border-darkmode-400 text-slate-300;
                &.active {
                    @apply bg-darkmode-400/80;
                }
                .remove {
                    @apply border-darkmode-400;
                }
            }
        }
        &.plugin-dropdown_input .ts-control.focus {
            @apply border-transparent;
        }
        .ts-dropdown {
            @apply bg-darkmode-700 border-darkmode-800/80 text-slate-300;
            &.plugin-dropdown_input .dropdown-input-wrap input {
                @apply bg-darkmode-600 border-darkmode-800/80;
            }
            .optgroup-header {
                @apply bg-darkmode-800 text-slate-400;
            }
            .create {
                @apply bg-darkmode-800 text-slate-300;
                &:hover {
                    @apply bg-darkmode-600;
                }
            }
            .option {
                &:hover:not(.active) {
                    @apply bg-darkmode-600 text-slate-300;
                }
            }
            .dropdown-header {
                @apply bg-darkmode-800 border-darkmode-700 text-slate-300;
                .dropdown-header-close {
                    @apply text-slate-300;
                }
            }
        }
    }
}
