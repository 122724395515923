.search {
    @apply relative;
    .search__input {
        @apply w-56 shadow-none rounded-full bg-slate-300/50 pr-8 focus:border-transparent dark:bg-darkmode-400/70;
    }
    .search__icon {
        @apply w-5 h-5 absolute my-auto inset-y-0 mr-3 right-0 text-slate-600;
    }
}

.top-bar-boxed {
    .search {
        .search__input {
            @apply bg-slate-200;
        }
    }
}
